<template>
  <div class="hello">
    <h1>{{ title }}</h1>
    <p>
      Simple Vue3 app
    </p>
    <div>
      Counter: {{ counter }}
    </div>
    <button v-on:click="hello">Click me</button><br />
    <button v-on:click="startTimer">Start timer</button><br />
    <button v-on:click="stopTimer">Stop timer</button>
  </div>
</template>

<script>
let timer;
export default {
  name: 'HelloWorld',
  data() {
    return {
      counter: 0,
      title: 'Hello world'
    }
  },
  methods: {
    hello() {
      this.counter++;
    },
    stopTimer() {
      clearTimeout(timer);
    },
    startTimer() {
      timer = setInterval(() => {
        this.counter++
      }, 1000)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
